<template>
  <div class="content capital-account-init-list">
    <section style="margin-bottom: 1%">
      <a-button type="primary" @click="createHandle">
        <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
        {{ $t('资金账户初始化') }}
      </a-button>
    </section>
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.orderSn"
            :placeholder="$t('请输入单据编号')"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      ref="CommonTableRef"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :showSelect="true"
      :scroll="{ x: 1500 }"
      :selectedRowKeys.sync="selectedRowKeys"
      :total="total"
      rowKey="id"
      @select="onSelectChange"
      @search="getList"
    >
      <template v-slot:header>
        <div class="flex-ct">
          <OperateBtn
            api="checkCapitalInit"
            :text="$t('审核')"
            :params="{
              idList: selectedRowKeys,
            }"
            :disabled="selectedRows.length === 0"
            @success="searchHandle"
          />
        </div>
      </template>
      <template v-slot:orderSn="{ text, record }">
        <a @click="viewDetail(record)">{{ text }}</a>
      </template>
      <template v-slot:name="{ record }">
        <div v-for="(item, index) in record.detailList" :key="index">
          {{ item.capitalAccountName }}
        </div>
      </template>
      <template v-slot:currency="{ record }">
        <div v-for="(item, index) in record.detailList" :key="index">
          {{ $store.getters.currencyListMapping[item.currency] }}
        </div>
      </template>
      <template v-slot:balance="{ record }">
        <div v-for="(item, index) in record.detailList" :key="index">
          <PriceInput :value="item.balance" :currency="item.currency" :showTxt="true" />
        </div>
      </template>
      <template v-slot:settlementMethod="{ record }">
        <div v-for="(item, index) in record.detailList" :key="index">
          {{ item.settlementMethod }}
        </div>
      </template>
    </CommonTable>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
import { convertKeysToCamelCase } from '@/common'
export default {
  name: 'CapitalAccountInitList',
  components: { CommonTable },
  data() {
    return {
      docType: 'InitCapitalBalance',
      formData: {},
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      total: 0,
      loading: false,

      columns: [],
    }
  },
  mounted() {
    this.setColumns()
  },
  activated() {
    this.searchHandle()
  },
  computed: {},
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.setColumns()
      this.searchHandle(1)
    },
    setColumns() {
      this.columns = [
        {
          title: this.$t('单据编号'),
          dataIndex: 'orderSn',
          width: 200,
          scopedSlots: { customRender: 'orderSn' },
        },
        {
          title: this.$t('组织'),
          dataIndex: 'businessUnitName',
        },
        {
          title: this.$t('状态'),
          dataIndex: 'status',
          customRender: (text) => this.$store.getters.initCapitalBalanceStatusMapping[text],
        },
        {
          title: this.$t('启用期间'),
          dataIndex: 'accountingPeriod',
          customRender: (text) => (text ? text.split('-').join(this.$t('年')) + this.$t('期') : ''),
        },
        {
          title: this.$t('银行账户'),
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: this.$t('币别'),
          dataIndex: 'currency',
          scopedSlots: { customRender: 'currency' },
        },
        {
          title: this.$t('期初余额'),
          dataIndex: 'balance',
          scopedSlots: { customRender: 'balance' },
        },
        // {
        //   title: this.$t('结算方式'),
        //   dataIndex: 'settlementMethod',
        //   scopedSlots: { customRender: 'settlementMethod' },
        // },
      ]
    },
    searchHandle(pageNo) {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    createHandle() {
      this.$router.push({ name: 'capitalAccountInitDetail' })
    },
    async getList(params) {
      this.loading = true
      await http({
        url: api.getCapitalInitList,
        data: {
          ...this.formData,
          ...params,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    viewDetail(record) {
      this.addPane({
        view: 'capitalAccountInitDetail/' + record.orderSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.orderSn,
        },
        route: `/capitalManage/capitalAccountInitDetail?id=${record.id}&sn=${record.orderSn}`,
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
</script>
